.App {
  text-align: center;
  font-family: 'Bebas Neue', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

 h1, h2, h3, h4, h5, h6{
  font-family: 'Bebas Neue', sans-serif;
} 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 140px) ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ant-upload-list{
  margin-top: 10px !important;
}

label{
  font-size: small;
  font-weight: 700;
  margin-top: 15;
}

sup{
  color: crimson;
}

/* .card-overlay:hover{
  display: none;
} */

a{
  text-decoration: none !important;
}

.nav-link1, #basic-nav-dropdown1 {
  color: white !important;
  padding: none !important;
  font-size: 1.2em !important;   
  font-weight: 700 !important;           
     text-transform: uppercase; 
     font-family: 'Bebas Neue', sans-serif;                                                                                      
}

.Link, #basic-nav-dropdown {
text-transform: uppercase;
font-weight: 600 !important;
font-size: 1.4em !important;
font-weight: 700 !important;
font-family: 'Bebas Neue', sans-serif;
}

.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
